import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {TransactionButton} from '../functions/Trx';

import bamboo from '../img/graphics/Bamboo3.png';






class GovernanceTokenPage extends Component {
   constructor (props) {
       super(props)
       let nowTimeStamp = new Date().getTime()
       let startTimeStamp = parseInt(nowTimeStamp/(7*86400*1000))*(7*86400*1000)+(7*86400*1000)
       let endDateTimeStamp = nowTimeStamp + (4 * 365 * 86400*1000)
       let maxDate = new Date(endDateTimeStamp )
       console.log(this.props)
      
       this.state = {
         startDate: new Date(startTimeStamp) ,
         selectedDate: new Date(startTimeStamp),
         newLockTime: startTimeStamp ,
         maxDate: maxDate,
       };
       this.setDate = this.setDate.bind(this);
       this.submitDate = this.submitDate.bind(this);
   }


   setDate(date) {
       console.log(date)
       console.log(this.props.lockTime)
       this.setState({selectedDate: date})
       this.setState({newLockTime: date.getTime()})
       }


   submitDate(e) {
       e.preventDefault();
       let main = this.state.startDate
       console.log(main.format('L'));
   }


   async componentDidMount() {
       let now = new Date().getTime()/1000
       let testChainData = {}
       testChainData['Mumbai (Polygon testnet)'] = [10009,'0x1AFB455e5431a41f97d37179672377BBa973Fd87']
       testChainData['Binance Smart Chain'] = [10002,'0xb14Aa5a12B9EbdeDD9C18E67dFbb77B1b344B85c']
       testChainData['Fuji (Avalanche testnet)'] = [10006,'0x0A668c3280148b19D892F6C291576E41510f3Db8']
       testChainData['Rinkeby'] = [10001,'0x3154Bc7E5BFC78D90dE3E4Ee91208F056BC5071e']
       testChainData['Arbitrum (Rinkeby testnet)'] = [10010,'0x3154Bc7E5BFC78D90dE3E4Ee91208F056BC5071e']
       testChainData['Fantom (testnet)'] = [10012,'0x684C7183361529CB730D2e2cEeb9bacd70571916']
       let chainData = testChainData
       let testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']
       if (this.props.chain === "rinkebyArbitrum"){  testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Fantom (testnet)']}
       if (this.props.chain === "MaticTestnet"){  testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}
       if (this.props.chain === "fantomTestnet"){  testChains = ['Rinkeby','Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)']}
       if (this.props.chain === "fuji"){  testChains = ['Rinkeby','Binance Smart Chain','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}
       if (this.props.chain === "rinkeby"){  testChains = ['Binance Smart Chain','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}
       if (this.props.chain === "bscTestnet"){  testChains = ['Rinkeby','Fuji (Avalanche testnet)','Mumbai (Polygon testnet)','Arbitrum (Rinkeby testnet)','Fantom (testnet)']}
       let chains = testChains
       this.setState({
           GovernanceTokenBalance: this.props.GovernanceTokenBalance,
           GovernanceTokenStakeBalance: this.props.GovernanceTokenStakeBalance,
           stakeModalOpen: false,
           unstakeModalOpen: false,
           createLockModalOpen: false,
           extendLockModalOpen: false,
           increaseLockModalOpen: false,
           bridgeModalOpen: false,
           chooseChainModalOpen: false,
           now:now,
           selectedChain: 'Select a chain',
           selectedChainID: 0,
           chainData: chainData,
           chains: chains,
           destinationChainBalance: 0,
           destinationChainTargetBalance: this.props.web3.utils.toBigInt(0),
           checkingBridgeResultOnDestinationChain: false,
           bridgingInProcess: false,
           confirmedOnSourceChain: false,
           confirmedOnDestinationChain: false,
           VotingEscrowApproved: false
       });
       this.checkVotingEscrowAllowance()
      
   }
  
   checkVotingEscrowAllowance = async () => {
       if (typeof(this.props.VotingEscrow) != 'undefined'){
           // check if the the allowance has been set for the VotingEscrow contract to spend TWIN
           let addressToApprove = this.props.VotingEscrow._address
           let allowance = await this.props.GovernanceToken.methods.allowance(this.props.address, addressToApprove).call()
           console.log(allowance)
           if (allowance > 100000000 * 1e18) {
               this.setState({VotingEscrowApproved: true})
           }
           else {
               this.setState({VotingEscrowApproved: false})
           }
           console.log(this.state.VotingEscrowApproved)
       }
   };


  


   listChains() {       
       if (typeof(this.state.chains) != 'undefined'){
           let chainOptions = this.state.chains.map((element,index) =>
                   <li key={index} className="list-group-item selectAssetItem" role="button" onClick={()=>this.selectChain(element)}>
                       <div className="row">
                           <div className="col"><b>{element}</b></div>
                          
                       </div>                       
                   </li>
           );
           return(chainOptions)
       }
       else {return ('')}
   }


   openCreateLockModal = async () => {
       this.setState({ createLockModalOpen: true })    
   };
   closeCreateLockModal = () => this.setState({ createLockModalOpen: false });


   openExtendLockModal = async () => {
       console.log(this.props.lockDate)
       let startDate = new Date((parseInt(this.props.lockDate)+691200)*1000)
       console.log(startDate)
       this.setState({startDate})
       this.setState({selectedDate:startDate})
       this.setState({ extendLockModalOpen: true })
   };
   closeExtendLockModal = () => this.setState({ extendLockModalOpen: false });


   openIncreaseLockModal = async () => {
       this.setState({ increaseLockModalOpen: true })
   };
   closeIncreaseLockModal = () => this.setState({ increaseLockModalOpen: false });


   checkLockAmount = async() =>{
       let amountToLock = document.getElementById('amountToLock').value
       var amountRaw = this.props.web3.utils.toWei(amountToLock.toString(), 'ether')
       var lockAmount = this.props.web3.utils.toBigInt(amountRaw)
       this.setState({lockAmount})
   }


   checkIncreaseLockAmount = async() =>{
       console.log("DEBUG")
       let amountToLock = document.getElementById('amountToLock').value
       var amountRaw = this.props.web3.utils.toWei(amountToLock.toString(), 'ether')
       var lockAmount = this.props.web3.utils.toBigInt(amountRaw)
       this.setState({lockAmount})
   }


   onSuccessApprove = async() =>{
       this.checkVotingEscrowAllowance()
   }


  
   onSuccessLock = async() => {
       this.closeCreateLockModal()
       await this.props.checkRewards();
   }


   onSuccessLockTime = async() => {
       this.closeExtendLockModal()
       await this.props.checkRewards();
   }


   onSuccessLockAmount = async() => {
       this.closeIncreaseLockModal()
       await this.props.checkRewards();
      
   }


   onSuccessWithdraw = async() =>{ 
       await this.props.checkRewards();
   }


   roundDown = (n,d) => {
       n = Math.floor(n*(10**d))
  
       n = n/(10**d)
       return n
   }


   setMaxLockAmount = async() =>{
       let amount = 0
       if (typeof(this.props.GovernanceTokenBalance) != 'undefined'){
           amount = parseFloat(this.props.GovernanceTokenBalance * 0.999999999)
       }
       document.getElementById('amountToLock').value = this.roundDown(amount,12);
       await this.checkLockAmount()
       return
   }








   claim = async() =>{ 
       console.log("Claiming rewards",this)
       let message = "Claiming rewards"
       this.props.openMessageBox(message)
       console.log(this.props.RewardsMachine)
       let gasPrice = 10000000000;
       try {
         const result = await axios.get("https://gasstation-mainnet.matic.network/");
         console.log(result.data);
         gasPrice = (parseInt(result.data['standard'])+2)*1000000000;
         console.log(gasPrice)
       }
       catch (error) {
         console.error(error);
       }
       try{await this.props.RewardsMachine.methods.claimRewards().send({from: this.props.address, gasPrice: gasPrice})}
       catch(e){
           console.log(e['message'])
           message = e['message']
           this.props.openMessageBox(message)
           await this.props.sleep(5000)
           this.props.closeMessageBox();
           return (false)
       }  
       await this.props.checkRewards();
       this.props.closeMessageBox()
   };


   createRewards = async() =>{ 
       console.log("Creating rewards",this)
       let message = "Minting the weekly rewards pool"
       this.props.openMessageBox(message)
       console.log(this.props.RewardsMachine)
       let gasPrice = 10000000000;
       try {
         const result = await axios.get("https://gasstation-mainnet.matic.network/");
         console.log(result.data);
         gasPrice = (parseInt(result.data['standard'])+2)*1000000000;
         console.log(gasPrice)
       }
       catch (error) {
         console.error(error);
       }
       let result = await this.props.RewardsMachine.methods.createRewards().send({from: this.props.address, gasPrice: gasPrice})
       console.log(result)
      
       await this.props.checkRewards();
       this.props.closeMessageBox()
   };




   render() {
       const tooltip1 = props => (
           <Tooltip {...props}>
           TWIN tokens must be staked and locked in order to participate in governance votings.
           </Tooltip>
       );

       const tooltip4 = props => (
           <Tooltip {...props}>Amount of TWIN currently outstanding</Tooltip>
       );
       const tooltip5 = props => (
           <Tooltip {...props}>
           The maximum amount of TWIN is limited at 100m. All TWIN tokens are pre-mineds.
           </Tooltip>
       );
       const tooltip6 = props => (
           <Tooltip {...props}>
           Total value of TWIN circulating, i.e.: Amount of TWIN currently outstanding multiplied by current TWIN price.
           </Tooltip>
       );
       const tooltip7 = props => (
           <Tooltip {...props}>
           Max. possible supply of TWIN (@100m) multiplied by current TWIN price.
           </Tooltip>
       );

      
      
       return (
          






           <div className="container-fluid w-100">




               <Modal show={this.state.createLockModalOpen} onHide={this.closeCreateLockModal}>
                   <Modal.Header closeButton>
                       <Modal.Title>Lock TWIN Tokens</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                       <div className="input-group mb-3">
                           <div className="input-group-prepend">
                               <label className="input-group-text" for="amountToLock">Amount to lock:</label>
                           </div>
                           <input className="form-control border" type='number' onChange={() =>this.checkLockAmount()} id='amountToLock'></input>
                           <div className="input-group-append">
                               <div onClick={this.setMaxLockAmount} role="button" className="badge badge-accent" for="amountToLock">Max</div>
                           </div>
                       </div>
                       <div className="input-group mb-3">
                           <div className="input-group-prepend">
                               <label className="input-group-text" for="lockDate">Lock until:</label>
                           </div>
                           <div className="input-group-append border" id="lockDate">
                               <DatePicker
                                 selected={ this.state.selectedDate }
                                 minDate={this.state.startDate}
                                 maxDate={this.state.maxDate}
                                 onChange={ this.setDate }
                                 name="startDate"
                                 dateFormat="MM/dd/yyyy"
                               />
                           </div>
                       </div>
                   </Modal.Body>
                   <Modal.Footer>
                       {this.state.VotingEscrowApproved === false
                           ?
                               <TransactionButton
                                   contractAddress={this.props.GovernanceToken_Address}
                                   abi = {this.props.ERC20_ABI}
                                   functionName= 'approve'
                                   args =  {[this.props.VotingEscrow_Address,this.props.web3.utils.toBigInt(2**255)]} // Example, adjust as needed
                                   text="Approve"
                                   onSuccess={this.onSuccessApprove}
                               />
                           :
                           ''
                       }
                       <div>
                       {this.state.VotingEscrowApproved === true && (this.props.GovernanceTokenBalance * 1e18 < this.state.lockAmount) ===  false && this.state.lockAmount > 0
                           ?
                               <TransactionButton
                                   abi={this.props.VotingEscrow_ABI}
                                   contractAddress={this.props.VotingEscrow_Address}
                                   functionName="createLock"
                                   args={[this.state.lockAmount,parseInt(this.state.newLockTime/1000)]}
                                   text="Lock"
                                   onSuccess = {this.onSuccessLock}
                               />
                               :''
                           }
                           {this.props.GovernanceTokenBalance * 1e18 < this.state.lockAmount
                           ?
                           <Button className="btn w-100 issuaaButtonDisabled" disabled>
                               Amount too high
                           </Button>
                           :''
                           }
                       </div>
                      
                   </Modal.Footer>
               </Modal>


               <Modal show={this.state.extendLockModalOpen} onHide={this.closeExtendLockModal}>
                   <Modal.Header closeButton>
                       <Modal.Title>Extend the lock time of your TWIN Tokens</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                       <div className="input-group mb-3">
                           <div className="input-group-prepend">
                               <label className="input-group-text" for="lockDate">Lock until:</label>
                           </div>
                           <div className="input-group-append border" id="lockDate">
                               <DatePicker
                                 selected={ this.state.selectedDate}
                                 minDate = { this.state.startDate}
                                 maxDate = {this.state.maxDate}
                                 onChange={(date) => this.setDate(date)}
                                 name="startDate"
                                 dateFormat="MM/dd/yyyy"
                               />
                           </div>
                       </div>
                   </Modal.Body>
                   <Modal.Footer>
                       <div>
                           <TransactionButton
                               abi={this.props.VotingEscrow_ABI}
                               contractAddress={this.props.VotingEscrow_Address}
                               functionName="increaseLockTime"
                               args={[parseInt(parseInt(this.state.newLockTime)/1e3)]}
                               text="Extend lock time"
                               onSuccess = {this.onSuccessLockTime}
                           />
                       </div>
                      
                   </Modal.Footer>
               </Modal>


               <Modal show={this.state.increaseLockModalOpen} onHide={this.closeIncreaseLockModal}>
                   <Modal.Header closeButton>
                       <Modal.Title>Increase the amount of locked TWIN Tokens</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                       <div className="input-group mb-3">
                           <div className="input-group-prepend">
                               <label className="input-group-text" for="amountToLock">Amount to lock:</label>
                           </div>
                           <input className="form-control border" type='number' onChange={() =>this.checkIncreaseLockAmount()} id='amountToLock'></input>
                           <div className="input-group-append">
                               <div onClick={this.setMaxLockAmount} role="button" className="badge badge-accent" for="amountToUnStake">Max</div>
                           </div>
                       </div>
                   </Modal.Body>
                   <Modal.Footer>
                  
                   {this.state.VotingEscrowApproved === true && (this.props.GovernanceTokenBalance * 1e18 < this.state.lockAmount) ===  false && this.state.lockAmount > 0
                       ?
                       <TransactionButton
                           abi={this.props.VotingEscrow_ABI}
                           contractAddress={this.props.VotingEscrow_Address}
                           functionName="increaseLockAmount"
                           args={[this.state.lockAmount]}
                           text="Increase locked amount"
                           onSuccess = {this.onSuccessLockAmount}
                       />
                       :''
                   }
                   {this.props.GovernanceTokenBalance * 1e18 < this.state.lockAmount
                           ?
                           <Button className="btn w-100 issuaaButtonDisabled" disabled>
                               Amount too high
                           </Button>
                           :''
                   }
                      
                      
                   </Modal.Footer>
               </Modal>




               <div className="row">
                   <div className="col"></div>
                   <div className='col-6'>
                        <div
                            className="col-12" 
                            id="pageHeadline"
                        >
                           TWIN token
                       </div>
                       <div id="mainBox" className=" container text-black p-2">
                            <img src={bamboo} alt="Bamboo" id="bambooBottomRight"/>  
                            <div className="container p-3 text-light">
                               <div className="container px-4 pr-4 py-2">
                                   <div className="subBox  px-4 py-4 mt-3">
                                       <div className="h5 row">
                                           <div className="col-7 text-black">Liquid TWIN balance:</div>
                                           <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.GovernanceTokenBalance),0)}</div>                                            
                                       </div>
                                       <div className="h5 text-fuchsia row">
                                           <div className="col-7 text-black">Locked TWIN balance:</div>
                                           <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.GovernanceTokenTotalBalanceAllChains-this.props.GovernanceTokenBalance),0)}</div>                                            
                                       </div>
                                       <div className="h5 text-fuchsia row bold">
                                           <div className="col-7 text-black bold">Total TWIN balance:</div>
                                           <div className="col-5 text-right text-black bold">{this.props.outputNumber(parseFloat(this.props.GovernanceTokenTotalBalanceAllChains),0)}</div>                                            
                                       </div>
                                      
                                      


                                      
                                   </div>


                                  
                                   <div className="subBox  px-4 py-4 mt-4">
                                       <div className="h5 text-fuchsia row">
                                           <div className="col-7 text-black">Your veTWIN balance:</div>
                                           <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.veISSBalance),0)}</div>                                            
                                       </div>
                                       <div className="row justify-content-between">
                                           <div className="col-7">
                                               <OverlayTrigger placement="top" overlay={tooltip1}>
                                                   <InfoOutlinedIcon />
                                               </OverlayTrigger>
                                               Current lock ends on
                                           </div>
                                           <div className="col-4 text-right">
                                               {(this.props.lockDate   > this.state.now
                                                   ?
                                                   this.props.timeStampToDate(this.props.lockDate,0)
                                                   :
                                                   'n.a.'
                                               )}
                                           </div>                                            
                                       </div>
                                       {(this.props.lockDate   < this.state.now && this.props.GovernanceTokenStakeBalance >1
                                           ?
                                           <div className="m-2 pt-3 pb-3">
                                               <TransactionButton
                                                   abi={this.props.VotingEscrow_ABI}
                                                   contractAddress={this.props.VotingEscrow_Address}
                                                   functionName="increasewithdrawockAmount"
                                                   args={[]}
                                                   text="Withdraw TWIN"
                                                   onSuccess = {this.onSuccessWithdraw}
                                               />
                                               <button className="selectAssetButton w-100" onClick={this.confirmWithdraw}>Unlock TWIN <KeyboardArrowDownOutlinedIcon /></button>
                                           </div>
                                           :
                                           ''
                                       )}


                                       {(this.props.GovernanceTokenBalance   >1 && this.props.lockDate   > this.state.now
                                           ?
                                           <div className="ml-2 mr-2 pt-3">
                                               <button className="selectAssetButton w-100" onClick={this.openIncreaseLockModal}>Increase locked amount <KeyboardArrowDownOutlinedIcon /></button>
                                           </div>
                                           :
                                           ''
                                       )}


                                       {(this.props.lockDate < 1
                                           ?
                                           <div className="ml-2 mr-2 pt-3">
                                               <button className="selectAssetButton w-100" onClick={this.openCreateLockModal}>Create a lock <KeyboardArrowDownOutlinedIcon /></button>
                                           </div>
                                           :
                                           ''
                                       )}


                                       {(this.props.veISSBalance > 0
                                           ?
                                           <div className="ml-2 mr-2 pt-3">
                                               <button className="selectAssetButton w-100" onClick={this.openExtendLockModal}>Extend lock time <KeyboardArrowDownOutlinedIcon /></button>
                                           </div>
                                           :
                                           ''
                                       )}
                                       <div className="h5 text-fuchsia row mt-3">
                                           <div className="col-7 text-black">Total veTWIN supply:</div>
                                           <div className="col-5 text-right text-black">{this.props.outputNumber(parseFloat(this.props.totalVeISSSupply),0)}</div>                                            
                                       </div>
                                   </div>
                                   <div className="subBox  px-4 py-4 mt-4 mb-4">
                                       <div className="row justify-content-between">
                                           <div className="col-7">
                                               <OverlayTrigger placement="top" overlay={tooltip4}>
                                               <InfoOutlinedIcon />
                                               </OverlayTrigger>
                                               TWIN circulating
                                           </div>
                                           <div className="col-4 text-right">{this.props.outputNumber(this.props.ISSSupplyCurrent/1000000,1)}m</div>                                            
                                       </div>
                                       <div className="row justify-content-between">
                                           <div className="col-7">
                                               <OverlayTrigger placement="top" overlay={tooltip5}>
                                                    <InfoOutlinedIcon />
                                               </OverlayTrigger>
                                               TWIN total max. supply
                                           </div>
                                           <div className="col-4 text-right">{this.props.outputNumber(100,1)}m</div>                                            
                                       </div>
                                       <div className="row justify-content-between">
                                           <div className="col-7">
                                               <OverlayTrigger placement="top" overlay={tooltip6}>
                                                    <InfoOutlinedIcon />
                                               </OverlayTrigger>
                                               Current TWIN market cap
                                           </div>
                                           <div className="col-4 text-right">USDC {this.props.outputNumber(this.props.ISSPrice * this.props.ISSSupplyCurrent/1000000,2)}m</div>                                            
                                       </div>
                                       <div className="row justify-content-between">
                                           <div className="col-7">
                                               <OverlayTrigger placement="top" overlay={tooltip7}>
                                                    <InfoOutlinedIcon />
                                               </OverlayTrigger>
                                               Diluted TWIN market cap
                                           </div>
                                           <div className="col-4 text-right">USDC {this.props.outputNumber(this.props.ISSPrice * 100000000/1000000,2)}m</div>                                            
                                       </div>
                                   </div>
                               </div>
                           </div>
                          
                       </div>
                   </div>
                   <div className="col"></div>
               </div>
           </div>
        );
   }
}
export default GovernanceTokenPage;

