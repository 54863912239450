import '../styles/navbar.css';
import React from 'react';
import ConnectButton from './ConnectButton';



const Navbar = (props) => {

    return (
        <div className="w-100 pr-0 pl-0 text-right">
          <div className="row">
              <div className="col"></div>
              <div className="pr-5" >
                &nbsp;
                <div className='connectButton'>
                  <ConnectButton />
                </div>
              </div>             
          </div>
          <div className="row" style={{height: 0}}></div>    
        </div>
    )
  }
  export default Navbar;