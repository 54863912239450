import '../styles/sidebar.css';

import { Modal} from "react-bootstrap";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Button} from "react-bootstrap";
import logoWide from '../img/graphics/TWIN-LogoType.png';
import logo from '../img/graphics/TWIN_Logo.png';
import bridge from '../img/icons/Bridge.png';
import farm from '../img/icons/Farm.png';
import github from '../img/github_white.png';
import discord from '../img/discord_white.png';
import twitter from '../img/twitter_white.png';
import telegram from '../img/telegram_white.png';
import parachute from '../img/icons/Parachute.png';
import TagIcon from '@mui/icons-material/Tag';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CopyrightIcon from '@mui/icons-material/Copyright';
import PolicyIcon from '@mui/icons-material/Policy';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
                        
import React, {useState} from 'react';
import { Link } from 'react-router-dom';


const showPoints = false
const showBridge = false
const SidebarElement = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [disclaimerModalOpen,setDisclaimerModalOpen] = useState(false);
    
    
    
    const handleChangeView = (_page) => {
      props.changeView(_page);
    };

    const openDisclaimerModal = () => {
        setDisclaimerModalOpen(true)
    }
    const closeDisclaimerModal = () => {
        setDisclaimerModalOpen(false)
    }
  
    const collapseSidebar= () =>{
        if (collapsed === false) {
            setCollapsed(true)
        }
        else{
            setCollapsed(false)
        }
        
    }
    return (
    <div className="" style={{ display: 'flex', minHeight: '100vh' }}>
        <Modal show={disclaimerModalOpen} onHide={closeDisclaimerModal}>
            <Modal.Header className="border" closeButton>
                <Modal.Title>Legal notice</Modal.Title> 
            
            </Modal.Header>
            
            <Modal.Body className="" style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
             }} 
            >
                <div>
                  This website provides information and content of general nature about TWIN as a decentralized finance protocol (TWIN protocol) and as a decentralized autonomous organization (TWIN DAO). 
                    
                </div>
                <div>
                  The information and the content of this website may be wrong, subject to change or incomplete and may not be updated. You should not rely on this website or on any of the content therein for any kind of investment decision or for legal advice, financial advice, investment advice, or any kind of advice. 
                    
                </div>
                <div>
                  You always act at your own risk with respect to the content of this website and the TWIN protocol. In no way are the owners of, or contributors to the TWIN protocol, the TWIN DAO and to this website responsible for the actions, decisions, or other behavior taken or not taken by you in connection with this website or your potential interaction with the TWIN protocol. 
                    
                </div>
                <div>
                  Please note that the TWIN protocol is operated by the TWIN DAO and you are solely responsible for compliance with all laws that may apply to you and your use of the TWIN protocol and this website. 
                </div>
                <div>
                  Cryptocurrencies and blockchain technologies have been the subject of scrutiny by various regulatory bodies across the globe. 
                </div>
                <div>
                  TWIN DAO and its contributors make no representation regarding the application to your use of the TWIN protocol or this website of any laws, including, without limitation, those related to investments, tax, gaming, options, derivatives, or securities. Depending on the jurisdiction, the use of the TWIN protocol may be restricted. 
                  
                </div>
                <div>
                  You agree that TWIN DAO and its contributors are not responsible for determining whether or which laws may apply to you and your use of the TWIN protocol and this website. 
                </div>
                <div>
                  The TWIN DAO may restrict the use of the TWIN protocol to citizens and residents of certain jurisdictions. Particular restrictions apply for US residents who are strictly prohibited from purchasing, minting, farming or staking crypto assets using the TWIN protocol. 
                </div>
                <div>
                  Further, restricted persons should not interact with the TWIN protocol at any time. By interacting with any smart contract of the TWIN protocol you expressly and unconditionally affirm that you are not a resident of the US. 
                </div>
                <div>
                  All rights reserved. No guarantees, warranties or whatsoever given. <b>DeFi tools are not toys. Do your own research.</b>
                </div>

              </Modal.Body>
                        
          </Modal>
        <Sidebar
            className = "sideBar"
            collapsed={collapsed} 
            collapsedWidth="80px"
            backgroundColor= 'rgba(150,202,255, 1.0) !important'
            style={{
                borderWidth: "0px",
                fontSize: "15px",
                textTransform: "uppercase",
              }}
            >
            
                
            <Menu className=""
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                      if (level === 0) {
                        return {
                          color: disabled ? "#eee" : "#000000",
                          backgroundColor: active ? "#000000" : undefined,
                          "&:hover": {
                             backgroundColor: "#4169E1 !important",
                             color: "white !important",
                             borderRadius: "8px !important"
                           },
                        };
                      }
                    },
                  }}
            >
                <MenuItem
                    icon={<MenuOutlinedIcon />}
                    onClick={() => {
                    collapseSidebar();
                    }}
                    style={{ }}
                >
                </MenuItem>
                <div className="pl-3 pt-1 pb-5">
                    {collapsed ? 
                    <span
                    style ={{
                        fontFamily: "PosteramaRegular",
                        letterSpacing: "0.4rem",
                        color:"#84ff0d",
                    }}
                    >
                        <img className="" src={logo} alt="TWIN" width="50px"/>
                    </span>
                    :
                    <span
                    style ={{
                        fontFamily: "PosteramaRegular",
                        letterSpacing: "0.4rem",
                        fontSize: "35px",
                        color:"#84ff0d",
                    }}
                    >
                        <img className="" src={logoWide} alt="TWIN" width="200px"/>
                        
                    </span>
                    }
                </div>
                
                
                {props.fullProtocolIntegration ?
                    <Link to="/">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('mainpage')} >
                            <MenuItem icon={<GridViewOutlinedIcon />}>
                                <div className="" style={{}} >Dashboard</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/portfolio">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('portfolio')} >
                            <MenuItem icon={<WorkOutlineOutlinedIcon />}>
                                <div className="" style={{}}>Portfolio</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/mint">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('factory')} >
                            <MenuItem icon={<GavelRoundedIcon />}>
                                <div className="" style={{}}>Mint</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }

                {props.fullProtocolIntegration ?
                    <Link to="/pool">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('pool')} >
                            <MenuItem icon={<PieChartRoundedIcon />}>
                                <div className="" style={{}}>Pool</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration && props.farmActive ?
                    <Link to="/farm">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('farm')} >
                            <MenuItem icon={<img className="" src={farm} alt="Dashboard" width="30px"/>}>
                                <div className="" style={{}}>Farm</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/trade">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('tradeassets')} >
                            <MenuItem icon={<AttachMoneyOutlinedIcon />}>
                                <div className="" style={{}}>Trade</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/redeem">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('burnassets')} >
                            <MenuItem icon={<PaymentsOutlinedIcon />}>
                                <div>Redeem</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                {props.fullProtocolIntegration ?
                    <Link to="/governance">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('governance')} >
                            <MenuItem icon={<AccountBalanceOutlinedIcon />}>
                                <div>Governance</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                
                {showPoints && (props.chainName === 'Berachain'||  props.chainName === 'Berachain Artio')?
                    <Link to="/points">
                        <span className="p-0 m-0 w-100" type="button" onClick={() =>handleChangeView('points')} >
                            <MenuItem icon={<img className="" src={parachute} alt="Points" width="30px"/>}>
                                <div>Points</div>
                            </MenuItem>
                        </span>
                    </Link>
                    :
                    ''
                }
                <a href="https://twin-protocol.medium.com/" target="_blank" rel="noreferrer">
                        <MenuItem icon={<ChatBubbleOutlineOutlinedIcon/>}> Blog </MenuItem>
                </a>
                
                <div>&nbsp;</div>
                    
                    {props.chainName === 'Berachain ArtioXXXXXXX' & collapsed !== true ? 
                        <Link to="/test">
                            <div className="w-100 pr-4 pl-4 pb-2">
                                <Button size="sm" variant="royalblue" className="w-100 menuButton" onClick={() => handleChangeView('test')}>Test ETH / USD</Button>
                            </div>
                        </Link>
                        :
                        ''
                    }
                    
                    {(props.chainName === 'Berachain' || props.chainName === 'Berachain Artio')  & collapsed !== true ? 
                        <div className="w-100 pr-4 pl-4 pb-2">
                            <Button size="sm" variant="blue" className="w-100 menuButton menuButtonBlue">{props.chainName}</Button>
                        </div>
                        :
                        ''
                    }

                    {(props.chainName === 'Berachain' || props.chainName === 'Berachain Artio') && typeof props.GovernanceTokenTotalBalanceAllChains !== "undefined" && props.IDTBalanceWei < 1 && collapsed !== true ?
                        <Link to="/ISS">
                            <div className="w-100 pr-4 pl-4 pb-2">
                            <Button size="sm" variant="red" className="w-100 menuButton menuButtonGreen" onClick={() =>handleChangeView('governancetoken')}>{props.outputNumber(parseFloat(props.GovernanceTokenTotalBalanceAllChains),0)} TWIN</Button>
                        </div>
                        </Link>
                        :
                        ''
                    }
                    {(props.chainName === 'Berachain'  || props.chainName === 'Berachain Artio') && typeof props.GovernanceTokenTotalBalanceAllChains !== "undefined" && props.IDTBalanceWei > 0  & collapsed !== true ? 
                        <Link to="/IDT">
                            <div className="w-100 pr-4 pl-4 pb-2">
                                <Button size="sm" className="w-100 text-black menuButton menuButtonGreen" onClick={() =>handleChangeView('idtConversion')}>TWIND Conversion</Button>
                            </div>
                        </Link>
                        :
                        ''
                    }
                <div className="testCase">
                    <SubMenu
                        icon={<TagIcon/>}
                        label="Socials"
                       
                    >
                        <a href="https://discord.gg/9ZwbkdjmtJ" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={discord} alt="Discord" width="30px"/>}>
                            Discord
                            </MenuItem>
                        </a>
                        <a href="https://t.me/+OKQWss_9UDFhMDIy" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={telegram} alt="Telegram" width="30px"/>}> Telegram </MenuItem>
                        </a>
                        <a href="https://github.com/TWIN-Protocol/" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={github} alt="Github" width="30px"/>}> Github </MenuItem>
                        </a>
                        <a href="https://twitter.com/TwinProtocolFi" target="_blank" rel="noreferrer">
                            <MenuItem icon={<img src={twitter} alt="X" width="30px"/>}> X </MenuItem>
                        </a>
                    </SubMenu>    
                </div>
               <span className="p-0 m-0 w-100" type="button" onClick={() =>openDisclaimerModal()} >
                    <MenuItem icon={<PolicyIcon />}>
                        Legal
                    </MenuItem>
                </span>
                <div className="pl-4 pt-5 pt-1 pb-5">
                    {collapsed ? 
                    ''
                    :
                    <span>
                        <CopyrightIcon />
                        <span className="pl-2">TWIN 2024</span>
                    </span>
                    }
                </div>
                
            </Menu>
        </Sidebar>
    </div>
    )
    }
    export default SidebarElement;