import '../styles/mainpage.css';
import bamboo from '../img/graphics/Bamboo3.png';
import panda from '../img/graphics/Panda_Honey.png';
import honeypot from '../img/graphics/Honeypot.png';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';


class Mainpage extends Component {
    state = { 
    assetDetails: null,       
    };

    async componentDidMount() {
        
        this.setState({
            NumberOfAssets: 5,
            assets: this.props.assets,
            poolList: '',
            
        });
    };

    render() { 
        let poolOutput = '';
        //console.log(this.props.pools)
        if (typeof(this.props.pools) != 'undefined'){
            console.log(this.props.pools)
            poolOutput = this.props.pools.map((pool,index) =>

                <tr key={index}>
                    <td><div className="firstRow">{pool[0]}</div></td>
                    <td className="text-left">{pool[3]}</td>
                    <td className="text-right">{this.props.outputNumber(pool[10],2)}</td>
                    <td className="text-right">{this.props.outputNumber(parseFloat(pool[9])/1000,0)}</td>
                    <td className="text-right">{this.props.outputNumber(pool[2]/1e18,0)}</td>                                            
                    <td className="text-right">{this.props.outputNumber(pool[2] * parseInt(pool[4]) /parseInt(pool[5]) /1e18,0)}</td>
                    <td>
                        <div className="d-flex flex-row">
                            <Link to={`/trade/buy/${pool[11]}`}>
                                <div className="buyButton">Buy</div>
                            </Link>
                            <Link to={`/trade/sell/${pool[11]}`}>
                                <div className="sellButton">Sell</div>
                            </Link>
                        </div>
                    </td>
                    {this.props.farmActive ? <td className="text-right">{this.props.outputNumber(parseFloat(pool[6] * 0.4*100),0)}% - {this.props.outputNumber(parseFloat(pool[6] *100),0)}%</td>:''}
                </tr>
            ); 
        }
        else {
            <div className="spinner-border text-accent m-3" role="status"></div>
        }  
        return ( 

            <div className="mainContainer">
                <div className="middleBoxFull">
                    <img src={bamboo} alt="Bamboo" id="bambooBottomRight"/>  
                    <div className="row w-100 m-0 p-0">
                        <div className="col-12 contentContainer">
                            <img src={honeypot} alt="Honeypot" id="honeypotTopRight"/>  
                            <img src={panda} alt="Panda" id="pandaBottomRight"/>  

                            <div
                                className="col-12" 
                                id="pageHeadline"
                            >
                                Dashboard
                            </div>
                            <div className="w-100 mainBox" id="mainBox">
                                <div className="w-100">
                                    <div className="col w-100 m-0" id="innerBox">
                                        <div className="row infoRow">                                
                                            <div className="col-md-3 h-100 container text-black p-2">
                                                <div className="infoBox infoBoxBlack">
                                                    <div className='h1'><AttachMoneyOutlinedIcon fontSize="10px"/></div>
                                                    <div className="infoBoxItem">$ {this.props.outputNumber(parseFloat(this.props.ISSPrice),3)>0 ?
                                                        this.props.outputNumber(parseFloat(this.props.ISSPrice),3)
                                                        :
                                                        <span>...</span>
                                                        }
                                                    </div>
                                                    <div className='infoBoxItem2'>TWIN Price</div>
                                                </div>
                                            </div>

                                            <div className="col-md-3 h-100 container text-black p-2">
                                                <div className="infoBox infoBoxDarkBlue">
                                                    <div className='h1'><WorkOutlineOutlinedIcon fontSize="10px"/></div>
                                                    <div className="infoBoxItem">{typeof(this.props.assets)!== 'undefined' ? this.props.assets.length*2+1:<span>...</span>}</div>
                                                    <div className="infoBoxItem2">Assets</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 h-100 container text-black p-2">
                                                <div className="infoBox infoBoxBlue">
                                                    <div className='h1'><AttachMoneyOutlinedIcon fontSize="10px"/></div>
                                                    <div className="infoBoxItem">$ {typeof(this.props.totalLockedValue)!== 'undefined' ? this.props.outputNumber(this.props.totalLockedValue/1e6,2):<span>...</span>}m</div>
                                                    <div className="infoBoxItem2">Val. locked</div>
                                                </div>
                                            </div>  
                                            <div className="col-md-3 h-100 container text-black p-2">
                                                <div className="infoBox infoBoxOrange">
                                                    <div className='h1'><PieChartRoundedIcon fontSize="10px"/></div>
                                                    <div className="infoBoxItem">$ {typeof(this.props.assets)!== 'undefined' ? this.props.outputNumber(this.props.ISSMarketCapCurrent/1e6,2):<span>...</span>}m</div>
                                                    <div className="infoBoxItem2">TWIN Mrkt Cap</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row m-2">
                                    <div className="col p-3 pl-4 pb-0">
                                        <h3 className="text-black">TWIN Assets and pools:</h3>
                                        <br></br>
                                        <div className="">
                                        <table className="mainTable w-100">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Symbol</th>
                                                    <th className="text-left" scope="col">Asset</th>
                                                    <th className="text-right" scope="col">Price</th>
                                                    <th className="text-right" scope="col">Upper Limit</th>
                                                    <th className="text-right" scope="col">Pool liq.(USD)</th>                                            
                                                    <th className="text-right" scope="col">Your stake (USD)</th>
                                                    <th></th>
                                                    {this.props.farmActive ? <th className="text-right" scope="col">APR</th>:''}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {poolOutput}
                                            </tbody>
                                        </table>
                                        <div className="buffer"></div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
                                                
             


            
         );
    }
}
 
export default Mainpage;