

import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap';
import './scss/custom.css';
import 'bootstrap/dist/js/bootstrap.js';


import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { WagmiConfig } from 'wagmi'

// Define your custom chain


const beraArtioChain = {
  id: 80085, // Replace with actual chain ID for beraArtio
  name: 'BeraArtio',
  network: 'beraArtio',
  nativeCurrency: {
    name: 'Bera', // Replace with actual currency name
    symbol: 'BERA', // Replace with actual currency symbol
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://artio.rpc.berachain.com/']
    },
    public: {
      http: ['https://artio.rpc.berachain.com/']
    },
  },
  blockExplorers: {
    default: { name: 'BeraArtio Explorer', url: 'https://explorer.beraartio.net' }, // Replace with actual explorer details
  },
  testnet: false,
};

const chains = [beraArtioChain]
const projectId = '7cafe8d302b5b80c089eeec2204ede6f'

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}
  
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
createWeb3Modal({ wagmiConfig, projectId, chains,
  themeVariables:{
    '--w3m-font-family': 'OpenSansRegular',
    '--w3m-color-mix': '#000000',
    '--w3m-color-mix-strength': 0,
    '--w3m-accent': '#fa2aa0',
  }  
})
//const { open } = useWeb3Modal()
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <BrowserRouter>
    
      <WagmiConfig config={wagmiConfig}>
        <App />
        
      </WagmiConfig>
   
  </BrowserRouter>
  
  );
reportWebVitals();


